import jsPDF from 'jspdf';
import 'jspdf-autotable';
import QRCode from 'qrcode'
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import { mapState } from 'vuex';

function data() {
  return {}
}

async function getPdf(Data, titulo, pdfType) {
  const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

  const response1 = await this.$http.getFile('base64', {
    path:this.user.LogoRoute.replace('Public/','', null, 'i')
  });
  const divisiones1 = this.user.LogoRoute.split(".");
  const myBase64Image = 'data:image/'+divisiones1[1]+';base64,'+response1.data.data.base64;

  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let sizeText = 8;

  const doc = new jsPDF({
    orientation: 'p',
    unit: 'pt',
    format: [600, 842],
    putOnlyUsedFonts:true,
    floatPrecision: 16 // or "smart", default is 16
  });

  //***************** Footer Report *******************//

  const addFooters = (doc, HeaderJson, user) => {
    const pageCount = doc.internal.getNumberOfPages();
    let fecha  = DateFormater.formatTimeZoneToDateTime(new Date());
    let footerY = 827;
    
    doc.setFontSize(6);
    doc.setTextColor("#000");
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i)

      doc.autoTable({
        head: [{ TransaUserName: this.$t('label.PreparedBy'), TransaUserNameCertificate: this.$t('label.CertifiedBy'), ReceiverName: this.$t('label.ReceivedBy') }],
        body: [{
          TransaUserName: `${HeaderJson?.TransaUserName ?? ''} CI: ${HeaderJson?.TransaUserCi ?? ''}`.trim(),
          TransaUserNameCertificate: `${HeaderJson?.TransaUserNameCertificate ?? ''} ${HeaderJson?.TransaUserNameCertificate ? 'CI:' : ''} ${HeaderJson?.TransaUserCiCertificate ?? ''}`.trim(),
          ReceiverName: `${HeaderJson?.ReceiverName ?? ''} CI: ${HeaderJson?.ReceiverCardId ?? ''}`.trim(),
        }],
        startY: footerY-80,
        theme: 'grid',
        tableLineColor: 200,
        tableLineWidth: 0,
        margin: 15,
        tableWidth: 570,
        styles: {
          cellPadding: 5, 
          fontSize: 6,
        },
        columnStyles: {
          overflow: 'linebreak',
        },
        headStyles: {
          lineWidth: 0.5,
          fillColor: "#fff",
          textColor: "#000",
        },
      });

      doc.text(`${this.$t('label.pageReport')} ${i} ${this.$t('label.ofReport')} ${pageCount}`, doc.internal.pageSize.width - 15, footerY-15, {           
        align: 'right'
      })
      doc.text(`${this.$t('label.PrintedBy')}: ${user.UserName} ${user.UserLastName} ${user.UserCi}`, 15, footerY, {
        align: 'left'
      });
      doc.text(`${this.$t('label.printed')}: ${fecha}`, doc.internal.pageSize.width - 15, footerY, {
        align: 'right'
      });
    }
  }

  //***************** Header Report *******************//

  const addHeader = (doc, HeaderJson, user, myBase64Image, QR, titulo, sizeText) => {
    const pageCount = doc.internal.getNumberOfPages();

    doc.setFontSize(sizeText);
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i)

      //***************** Image *******************//
      doc.setDrawColor("#fff");
      doc.addImage(myBase64Image, "JPEG", 15, 12.5, 87, 60);

      //***************** Center *******************//
      doc.setDrawColor("#fff");
      doc.text(`${user?.CompanyName ?? ''} - ${user?.CompanyRif ?? ''}`, 300,  25, 'center');

      //***************** QR *******************//
      doc.addImage(QR, "JPEG", 280, 30, 40, 40);

      //***************** Right Table *******************//
      let head = [
        [
          {
            content: '',
            colSpan: 2,
          }
        ]
      ];
      let body = [
        [
          { content: this.$t('label.branch'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold' } },
          { content: user?.BranchJson?.[0]?.BranchName ?? '', colSpan: 1 },
        ],
        [
          { content: this.$t('label.code'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold' } },
          { content: HeaderJson?.DocumentCode ?? '', colSpan: 1 },
        ],
        [
          { content: this.$t('label.yard'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold' } },
          { content: HeaderJson?.YardAlias ?? '', colSpan: 1 },
        ],
        [
          { content: this.$t('label.CertificateDate'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold' } },
          { content: HeaderJson?.TransactionDate ? DateFormater.formatDateTimeWithSlash(HeaderJson.TransactionDate) : '', colSpan: 1 },
        ],
        [
          { content: this.$t('label.status'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold' } },
          { content: HeaderJson?.[`YardDocumentStatus${_lang}`] ?? '', colSpan: 1 },
        ],
      ]
      doc.autoTable({
        head: head,
        body: body,
        showHead: false,
        startY: 15,
        theme: 'plain',
        columnWidth: 70,
        tableLineColor: 200,
        tableLineWidth: 0,
        margin: {top: 15, right: 15, bottom: 100, left: 425},
        tableWidth: 180,
        styles: {
          cellPadding: 2,
          fontSize: 6,
        },
        columnStyles: {
          overflow: 'linebreak',
        },
      });


      


      //***************** title *******************//
      doc.setFillColor("#cccccc");
      doc.rect(15, 85, 570, 3, 'F');

      doc.setFillColor("#cccccc");
      doc.rect(15, 90, 570, 25, "FD");
      doc.text(titulo, 300, 105, 'center');
    }
  }

  //***************** addWatermark *******************//

	const addWatermark = (doc) => {
		const pageCount = doc.internal.getNumberOfPages();

		for (let i = 1; i <= pageCount; i++){
			doc.setPage(i)
			doc.saveGraphicsState();
			doc.setGState(new doc.GState({opacity: 0.2}));
			doc.setFontSize(80);
			doc.text(this.$t('label.canceledDocument'), 300, 421, {align: 'center', baseline: 'middle', angle: 25});
			doc.restoreGraphicsState();

		}
	}

  //***************** Header Body *******************//

  let HeaderJson = Data?.HeaderJson?.[0] ?? {};

  let HeaderTableReport = [
    [
      {
        content: '',
        colSpan: 6,
      }
    ]
  ]

  let BodyTableReport = [
    [
      { content: this.$t('label.client'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.ClientName ?? '', colSpan: 2, styles: { halign: 'left' } },
      { content: this.$t('label.type'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.[`CustomOperationDs${_lang}`] ?? '', colSpan: 2, styles: { halign: 'left' } },
    ],
    [
      { content: this.$t('label.vessel'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.VesselName ?? '', colSpan: 1, styles: { halign: 'left' } },
      { content: this.$t('label.voyage'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.Voyage ?? '', colSpan: 1, styles: { halign: 'left' } },
      { content: 'ATA / ETA ', colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.Arrival ? DateFormater.formatDateTimeWithSlash(HeaderJson.Arrival) : '', colSpan: 1, styles: { valign: 'middle', halign: 'left' } },
    ],
    [
      { content: this.$t('label.blCargoMaster'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.TpBlId == process.env.VUE_APP_TP_BL_ID_MASTER ? (HeaderJson?.BlNro ?? '') : (HeaderJson?.MasterBlNro ?? ''), colSpan: 1, styles: { halign: 'left' } },
      { content: this.$t('label.BlHouse'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.TpBlId == process.env.VUE_APP_TP_BL_ID_HOUSE ? (HeaderJson?.BlNro ?? '') : '', colSpan: 1, styles: { halign: 'left' } },
      { content: this.$t('label.classification'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.[`TpCargoName${_lang}`] ?? '', colSpan: 1, styles: { halign: 'left' } },
    ],
    [
      { content: this.$t('label.Procedence'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.Procedence ?? '', colSpan: 1, styles: { halign: 'left' } },
      { content: this.$t('label.BolipuertoCertificateEntry'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.ReceptionBpAct ?? '', colSpan: 1, styles: { halign: 'left' } },
      { content: this.$t('label.sidunea'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.Asycuda ?? '', colSpan: 1, styles: { halign: 'left' } },
    ],
    [
      { content: this.$t('label.customBroker'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.CustomBrokerName ?? '', colSpan: 2, styles: { halign: 'left' } },
      { content: this.$t('label.consolidator'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.ConsolidatorName ?? '', colSpan: 2, styles: { halign: 'left' } },
    ],
    [
      { content: this.$t('label.quantity'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: NumberFormater.formatNumber(HeaderJson?.QuantityReceived, 2), colSpan: 1, styles: { halign: 'left' } },
      { content: `${this.$t('label.weight')} (KGM)`, colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: NumberFormater.formatNumber(HeaderJson?.WeigthReception, 2), colSpan: 1, styles: { halign: 'left' } },
      { content: `${this.$t('label.volume')} (M³)`, colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: NumberFormater.formatNumber(HeaderJson?.VolumenReception, 2), colSpan: 1, styles: { halign: 'left' } },
    ],
  ]

  doc.autoTable({
    head: HeaderTableReport,
    body: BodyTableReport,
    showHead: false,
    startY: 125,
    theme: 'grid',
    columnWidth: 70,
    tableLineColor: 200,
    tableLineWidth: 0,
    margin: 15,
    tableWidth: 570,
    styles: {
      cellPadding: 5,
      fontSize: 6,
    },
    columnStyles: {
      overflow: 'linebreak',
    },
  });

  //***************** Body *******************//

  if (pdfType === 1) {
    const HeaderTablePackaging = (type, item) => {
      let HeaderTablePackaging = [
        [
          { content: this.$t('label.packaging'), colSpan: 5, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE" } },
          { content: this.$t('label.quantity'), colSpan: 5, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE" } },
          { content: `${this.$t('label.volume')} (M³)`, colSpan: 5, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE" } },
          { content: `${this.$t('label.weight')} (KGM)`, colSpan: 5, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE" } },
        ],
        [
          { content: item?.[`PackagingName${_lang}`] ?? '', colSpan: 5, styles: { valign: 'middle', halign: 'center', lineWidth: 0.5 } },
          { content: NumberFormater.formatNumber(item?.Quantity, 2), colSpan: 5, styles: { valign: 'middle', halign: 'right', lineWidth: 0.5 } },
          { content: NumberFormater.formatNumber(item?.Volumen, 2), colSpan: 5, styles: { valign: 'middle', halign: 'right', lineWidth: 0.5 } },
          { content: NumberFormater.formatNumber(item?.Weigth, 2), colSpan: 5, styles: { valign: 'middle', halign: 'right', lineWidth: 0.5 } },
        ],
      ]

      if (type == 1) {
        HeaderTablePackaging.push([
          { content: '#', colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 30, lineWidth: { top: 0.5 } } },
          { content: this.$t('label.receptionDate'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 50, lineWidth: { top: 0.5 } } },
          { content: this.$t('label.serial'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", lineWidth: { top: 0.5 } } },
          { content: this.$t('label.type'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", lineWidth: { top: 0.5 } } },
          { content: this.$t('label.Seals'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", lineWidth: { top: 0.5 } } },
          { content: this.$t('label.commodity'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", lineWidth: { top: 0.5 } } },
          { content: this.$t('label.imdg'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 40, lineWidth: { top: 0.5 } } },
          { content: this.$t('label.quantity'), colSpan: 2, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE", cellWidth: 10, lineWidth: { top: 0.5 } } },
          { content: `${this.$t('label.weight')} (KGM)`, colSpan: 2, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE", lineWidth: { top: 0.5 } } },
          { content: `${this.$t('label.volume')} (M³)`, colSpan: 2, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE", lineWidth: { top: 0.5 } } },
        ])
      }else if (type == 2) {
        HeaderTablePackaging.push([
          { content: '#', colSpan: 1, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 30, lineWidth: { top: 0.5 } } },
          { content: this.$t('label.receptionDate'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", minCellWidth: 20, lineWidth: { top: 0.5 } } },
          { content: this.$t('label.serial'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", minCellWidth: 20, lineWidth: { top: 0.5 } } },
          { content: this.$t('label.commodity'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 60, lineWidth: { top: 0.5 } } },
          { content: this.$t('label.Battery'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 40, lineWidth: { top: 0.5 } } },
          { content: this.$t('label.Extinguisher'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 10, lineWidth: { top: 0.5 } } },
          { content: this.$t('label.KeySets'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 40, lineWidth: { top: 0.5 } } },
          { content: this.$t('label.imdg'), colSpan: 1, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 40, lineWidth: { top: 0.5 } } },
          { content: this.$t('label.quantity'), colSpan: 2, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE", cellWidth: 10, lineWidth: { top: 0.5 } } },
          { content: `${this.$t('label.volume')} (M³)`, colSpan: 2, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE", minCellWidth: 20, lineWidth: { top: 0.5 } } },
          { content: `${this.$t('label.weight')} (KGM)`, colSpan: 2, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE", minCellWidth: 20, lineWidth: { top: 0.5 } } }, 
        ])
      }else {
        HeaderTablePackaging.push([
          { content: '#', colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 30, lineWidth: { top: 0.5 } } },
          { content: this.$t('label.receptionDate'), colSpan: 3, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 70, lineWidth: { top: 0.5 } } },
          { content: this.$t('label.serial'), colSpan: 3, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", lineWidth: { top: 0.5 } } },
          { content: this.$t('label.commodity'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", lineWidth: { top: 0.5 } } },
          { content: this.$t('label.Seals'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", lineWidth: { top: 0.5 } } },
          { content: this.$t('label.imdg'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 40, lineWidth: { top: 0.5 } } },
          { content: this.$t('label.quantity'), colSpan: 2, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE", cellWidth: 5, lineWidth: { top: 0.5 } } },
          { content: `${this.$t('label.volume')} (M³)`, colSpan: 2, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE", lineWidth: { top: 0.5 } } },
          { content: `${this.$t('label.weight')} (KGM)`, colSpan: 2, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE", lineWidth: { top: 0.5 } } },
        ])
      }

      return HeaderTablePackaging;
    };

    const BodyTablePackaging = (type, item) => {
      if (type === 1) {
        return [
          { content: item?.Nro ?? '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
          { content: item?.ReceptionDate ? DateFormater.formatDateTimeWithSlash(item.ReceptionDate) : '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
          { content: item?.Serial ? item?.Serial : 'N/A', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
          { content: item?.TpCargoDetailCode ?? '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
          { content: item?.Seals ?? '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
          { content: item?.[`CommodityName${_lang}`] ?? '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
          { content: item?.[`ImdgName${_lang}`] ?? '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
          { content: NumberFormater.formatNumber(item?.Quantity, 2), colSpan: 2, styles: { valign: 'middle', halign: 'right' } },
          { content: NumberFormater.formatNumber(item?.Volumen, 2), colSpan: 2, styles: { valign: 'middle', halign: 'right' } },
          { content: NumberFormater.formatNumber(item?.Weigth, 2), colSpan: 2, styles: { valign: 'middle', halign: 'right' } },
        ]
      }else if (type === 2) {
        return [
          { content: item?.Nro ?? '', colSpan: 1, styles: { valign: 'middle', halign: 'center' } },
          { content: item?.ReceptionDate ? DateFormater.formatDateTimeWithSlash(item.ReceptionDate) : '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
          { content: item?.Serial ? item?.Serial : 'N/A', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
          { content: item?.[`CommodityName${_lang}`] ?? '', colSpan: 2, styles: { valign: 'middle', halign: 'center'} },
          { content: item?.FgBattery ? 'x' : '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
          { content: item?.FgExtinguisher ? 'x' : '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
          { content: item?.FgKey ? 'x' : '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
          { content: item?.[`ImdgName${_lang}`] ?? '', colSpan: 1, styles: { valign: 'middle', halign: 'center'} },
          { content: NumberFormater.formatNumber(item?.Quantity, 2), colSpan: 2, styles: { valign: 'middle', halign: 'right' } },
          { content: NumberFormater.formatNumber(item?.Volumen, 2), colSpan: 2, styles: { valign: 'middle', halign: 'right' } },
          { content: NumberFormater.formatNumber(item?.Weigth, 2), colSpan: 2, styles: { valign: 'middle', halign: 'right' } },
        ]
      }else {
        return [
          { content: item?.Nro ?? '', colSpan: 2, styles: { valign: 'middle', halign: 'center'  } },
          { content: item?.ReceptionDate ? DateFormater.formatDateTimeWithSlash(item.ReceptionDate) : '', colSpan: 3, styles: { valign: 'middle', halign: 'center' } },
          { content: item?.Serial ? item?.Serial : 'N/A', colSpan: 3, styles: { valign: 'middle', halign: 'center' } },
          { content: item?.[`CommodityName${_lang}`] ?? '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
          { content: item?.Seals ?? '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
          { content: item?.[`ImdgName${_lang}`] ?? '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
          { content: NumberFormater.formatNumber(item?.Quantity, 2), colSpan: 2, styles: { valign: 'middle', halign: 'right' } },
          { content: NumberFormater.formatNumber(item?.Volumen, 2), colSpan: 2, styles: { valign: 'middle', halign: 'right' } },
          { content: NumberFormater.formatNumber(item?.Weigth, 2), colSpan: 2, styles: { valign: 'middle', halign: 'right' } },
        ]
      }
    }

    let PackagingJson = Data?.PackagingJson ?? [];

    PackagingJson.forEach((e) => {
      let fgLimit = false;
      let type = e.PackagingId == process.env.VUE_APP_PACKAGING_ID_CONTAINER ? 1 : 
        e.PackagingId == process.env.VUE_APP_PACKAGING_ID_VEHICLE ? 2 : 3
      let SerialJson = e?.SerialJson ?? [];
      let PackagingDetailJson = e?.PackagingDetailJson ?? [];
      let Detail = [...PackagingDetailJson, ...SerialJson];
      let finalY = doc.lastAutoTable.finalY;
      let header = HeaderTablePackaging(type, e);
      let body = [];

      Detail.forEach((el, index) => {
        el.Nro = index+1;
        body.push(BodyTablePackaging(type, el));
      })

      if ((finalY+15) >= 700) {
        fgLimit = true;
        doc.addPage([600, 842], 'p')
      }

      doc.autoTable({
        head: header,
        body: body,
        startY: !fgLimit ? finalY+15 : 125,
        theme: 'grid',
        tableLineColor: 200,
        tableLineWidth: 0,
        margin: {top: 135, right: 15, bottom: 100, left: 15},
        tableWidth: 570,
        styles: {
          cellPadding: 1, 
          fontSize: 7,
        },
        columnStyles: {
          overflow: 'linebreak',
        },
        headStyles: {
          fillColor: "#fff",
          textColor: "#000",
        },
      });
    })
  }else {
    let PackagingJson = Data?.PackagingJson ?? [];
    let finalY = doc.lastAutoTable.finalY;
    let totalQuantity = 0;
    let totalWeight = 0;
    let totalVolume = 0;
    let body = PackagingJson.map((e, index) => {
      totalQuantity += (e?.Quantity ?? 0);
      totalVolume += (e?.Volumen ?? 0);
      totalWeight += (e?.Weigth ?? 0);
      return {
        Nro: index+1,
        PackagingName: e?.[`PackagingName${_lang}`] ?? '',
        Quantity:  NumberFormater.formatNumber(e?.Quantity, 2),
        Volumen: NumberFormater.formatNumber(e?.Volumen, 2),
        Weigth: NumberFormater.formatNumber(e?.Weigth, 2),
      }
    });

    doc.autoTable({
      head: [{ 
        Nro: '#',
        PackagingName: this.$t('label.packaging'),
        Quantity: this.$t('label.quantity'),
        Volumen: `${this.$t('label.volume')} (M³)`,
        Weigth: `${this.$t('label.weight')} (KGM)`,
      }],
      body: body,
      foot: [{
        Nro: '',
        PackagingName: this.$t('label.total'),
        Quantity: NumberFormater.formatNumber(totalQuantity, 2),
        Volumen: NumberFormater.formatNumber(totalVolume, 2),
        Weigth: NumberFormater.formatNumber(totalWeight, 2),
      }],
      startY: finalY+15,
      theme: 'grid',
      tableLineColor: 200,
      tableLineWidth: 0,
      margin: {top: 135, right: 15, bottom: 100, left: 15},
      tableWidth: 570,
      styles: {
        cellPadding: 1, 
        fontSize: 7,
      },
      columnStyles: {
        overflow: 'linebreak',
        Quantity: { halign: 'right' },
        Volumen: { halign: 'right' },
        Weigth: { halign: 'right' },
      },
      headStyles: {
        fillColor: "#EEE",
        textColor: "#000",
        valign: 'middle',
        halign: 'right'
      },
      bodyStyles: {
        valign: 'middle',
        halign: 'center',
      },
      footStyles: {
        fillColor: "#fff",
        textColor: "#000",
        valign: 'middle',
        halign: 'right',
        lineWidth: 0.5,
      },
      didParseCell: function (data) {
        if (data.cell.section === 'head' && (data.column.dataKey == 'PackagingName' || data.column.dataKey == 'Nro')){
          data.cell.styles.halign = 'center'
        }
      }
    });
  }

  let fileName = documento;
  let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
  today = today.replaceAll("/","");
  let time = new Date();
  time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();

  let QR = '';
  QRCode.toDataURL(HeaderJson?.DocumentQrCode ?? '', { type: 'image/jpeg' }, function (err, url) {
    if (err) throw false

    QR = url
  });

  addHeader(doc, HeaderJson, this.user, myBase64Image, QR, titulo, sizeText);
  addFooters(doc, HeaderJson, this.user);
  if (!HeaderJson?.FgActDocumentYard) {
		addWatermark(doc);
	}
  
  doc.save(`${fileName}${today}${time}.pdf`);//
  this.$notify({
    group: 'container',
    text: this.$t('label.reportSuccessfully'),
    type: "success"
  });
}

export default {
  data,
  methods: {
    getPdf,
  },
  computed:{
    ...mapState({
      user: state => state.auth.user,
      branch: state => state.auth.branch,
    }),
  },
}