<template>
  <form @submit.prevent="submitBody">
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars"/>
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      @update:show="resetForm"
    >
      <CRow class="justify-content-center">
        <CCol sm="12" lg="7">
          <CSelect
            size="sm"
            :placeholder="$t('label.select')"
            addLabelClasses="required text-right"
            :label="$t('label.type')"
            :horizontal="{label: 'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-9'}"
            :options="PdfOptions"
            v-model="form.Pdf"
          />
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click.stop="submit"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="resetForm"
        >
          <CIcon name="x" /><span class="ml-1"
            >{{$t('button.cancel')}}</span
          >
        </CButton>
      </template>
    </CModalExtended>
  </form>
</template>
<script>
import { mapState } from 'vuex';
import ModalMixin from '@/_mixins/modal';
import CertificateOfEntryMixin from '@/_mixins/certificate-of-entry';
import UpperCase from '@/_validations/uppercase-directive';

function data() {
  return {
    Loading: false,
    isSubmit: false,
    showModal: false,
    form: {
      Type: 0,
    },
  };
}

//-----------------------   Method    ------------------------
function submit() {
  try {
    this.isSubmit = true;
    this.Loading = true;
    this.$http.get("YardDocumentBlCargoReport", { YardDocumentId: this.CertificateOfEntryItem.YardDocumentId })
      .then(async(response) => {
        let pdf = response.data.data?.[0] ?? undefined;
        if (pdf) {
          await this.getPdf(pdf, this.$t('label.CertificateEntry'), this.form.Pdf);
          this.$emit('close');
        }
      }).catch( err => {
        this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
        });
      })
      .finally(() => {
        this.isSubmit = false;
        this.Loading = false;
      });
  } catch (e) {
    this.Loading = false;
    this.isSubmit = false;
    this.notifyError({text: e});
  }
}

function resetForm() {
  this.isSubmit = false;
  this.form = {
    Pdf: 0,
  }
  this.$emit('close');
}

//-----------------------  Computed  ------------------------
function title() {
  return `${this.$t('label.CertificateEntry')}: ${this.CertificateOfEntryItem?.DocumentCode ?? ''}`;
}

function PdfOptions(){
  return [
    {
      value: 0, 
      label: this.$t('label.resume'),
    },
    {
      value: 1,
      label: this.$t('label.detailed'),
    }
  ];
}

export default {
  name: 'certificate-of-entry-modal',
  components: {
   
  },
  props: { modal: Boolean, CertificateOfEntryItem: Object },
  data,
  mixins: [
    ModalMixin,
    CertificateOfEntryMixin,
  ],
  directives: UpperCase,
  methods: {
    submit,
    resetForm,
  },
  computed: {
    title,
    PdfOptions,
    ...mapState({
      branch: state => state.auth.branch,
    })
  },
  watch: {
    modal: async function (NewVal) {
      this.showModal = NewVal;
      if (NewVal) {
        this.form.Pdf = 0;
      }else {
        this.resetForm();
      }
    }
  },
};
</script>